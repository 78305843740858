var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.columns.length
    ? _c("table-page", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.isLoaded,
            expression: "isLoaded",
          },
        ],
        ref: _vm.$options.name,
        staticClass: "SeoPageTable",
        attrs: {
          tableName: _vm.$options.name,
          columns: _vm.columns,
          filterModel: _vm.filterModel,
          headerTitle: "seopages2665",
          tooltipMsg: "pmanualforseopa",
          requestFunction: _vm.requestFunction,
          defaultOrderBy: { column: "SpageUrl", ascending: 1 },
          modelId: "SpageId",
        },
        on: {
          addNewRecord: function ($event) {
            return _vm.redirectToDetail()
          },
          editRecord: function ($event) {
            return _vm.redirectToDetail($event)
          },
          filterChanged: function ($event) {
            return _vm.handleFilter($event)
          },
          removeFilters: function ($event) {
            return _vm.removeAllFilters($event)
          },
        },
        scopedSlots: _vm._u(
          [
            {
              key: "customActions",
              fn: function (list) {
                return [
                  _vm.showDeleteButton
                    ? _c("megau-button", {
                        attrs: {
                          classprop: "btn btn-customdanger ml-1",
                          icon: "trash-alt",
                          tooltip: _vm.$t("delete"),
                        },
                        on: {
                          handleClick: function ($event) {
                            return _vm.openDeleteModal(list.row.SpageId)
                          },
                        },
                      })
                    : _vm._e(),
                ]
              },
            },
            {
              key: "SpageUrl",
              fn: function (list) {
                return [
                  _c(
                    "a",
                    {
                      attrs: { href: list.row.UrlWithDomain, target: "_blank" },
                    },
                    [_vm._v(_vm._s(list.row.SpageUrl))]
                  ),
                ]
              },
            },
            {
              key: "SpageLanguage",
              fn: function (list) {
                return [
                  _c("div", {
                    domProps: {
                      textContent: _vm._s(list.row.SpageLanguage.toLowerCase()),
                    },
                  }),
                ]
              },
            },
            {
              key: "FirstArticleCreatedDate",
              fn: function (list) {
                return [
                  _vm._v(
                    _vm._s(_vm._f("dateTime")(list.row.FirstArticleCreatedDate))
                  ),
                ]
              },
            },
            {
              key: "FirstArticleVerifiedDate",
              fn: function (list) {
                return [
                  _vm._v(
                    _vm._s(
                      _vm._f("dateTime")(list.row.FirstArticleVerifiedDate)
                    )
                  ),
                ]
              },
            },
            {
              key: "SecondArticleCreatedDate",
              fn: function (list) {
                return [
                  _vm._v(
                    _vm._s(
                      _vm._f("dateTime")(list.row.SecondArticleCreatedDate)
                    )
                  ),
                ]
              },
            },
            {
              key: "SecondArticleVerifiedDate",
              fn: function (list) {
                return [
                  _vm._v(
                    _vm._s(
                      _vm._f("dateTime")(list.row.SecondArticleVerifiedDate)
                    )
                  ),
                ]
              },
            },
            {
              key: "FirstArticleIsVisible",
              fn: function (list) {
                return [
                  _c("boolean-display", {
                    attrs: {
                      boolValue: Boolean(list.row.FirstArticleIsVisible),
                    },
                  }),
                ]
              },
            },
            {
              key: "SecondArticleIsVisible",
              fn: function (list) {
                return [
                  _c("boolean-display", {
                    attrs: {
                      boolValue: Boolean(list.row.SecondArticleIsVisible),
                    },
                  }),
                ]
              },
            },
            {
              key: "SpgroupId",
              fn: function (list) {
                return [_vm._v(_vm._s(_vm.formatGroup(list.row.SpgroupId)))]
              },
            },
          ],
          null,
          false,
          1181263319
        ),
      })
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }